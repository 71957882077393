var autocompleteArr = [];
var autocompleteInputArr = [];
var itiObj;
function initializeAutocomplete() {
    const forms = document.querySelectorAll('[data-cta="autocomplete"]');
    const ctaAdditionalParams = getAdditionalCtaParams();

    for (let i = 0; i < forms.length; i++) {
        addFields(forms[i], i);
        addParamsFieldsToCtaForm(forms[i].querySelector('form'), ctaAdditionalParams);
        addPlaceIdFieldToCtaForm(forms[i].querySelector('form'));
        addAutocomplete(forms[i], i);
        forms[i].onsubmit = e => {
            // Nothing selected from dropdown (i.e. no lat/long present)
            if (!document.getElementById(`lat-${i}`).value || !document.getElementById(`lng-${i}`).value) {
                e.preventDefault(); // Prevent submission

                // Show error state
                let error = e.target.parentElement.querySelector('.w-form-fail');
                error.style.display = 'block';
            }
        }
    }
}

function addFields(element, index) {
    const fields = ['lat', 'lng', 'country', 'city', 'region', 'postalCode', 'line2'];  // 'line1'
    const form = element.querySelector('form');

    for (let i = 0; i < fields.length; i++) {
        let node = document.createElement('input');
        node.setAttribute('type', 'hidden');
        node.setAttribute('id', `${fields[i]}-${index}`);
        node.setAttribute('name', fields[i]); // Must match OrderIn        
        form.appendChild(node);
    }
}

function addAutocomplete(element, index) {
    const inputField = element.querySelector('[name="Address"]');
    const country = inputField.getAttribute('region');

    let options = {
        types: ['address'], // Array of strings; (cities), (regions), address
    };

    if (country) {
        options['componentRestrictions'] = {
            country: country
        }
    }

    if (inputField) {
        const autocomplete = new google.maps.places.Autocomplete(inputField, options);

        // Add listener to pre-fill hidden fields
        google.maps.event.addListener(autocomplete, "place_changed", function () {
            prepareSubmission(autocomplete, index);

            if (typeof nextStep === "function") {
                nextStep();
            }
        });

        // Don't allow submission via enter (as it won't trigger place_changed)
        inputField.addEventListener("keydown", function (event) {
            if (event.keyCode === 13) {
                event.preventDefault(); // TODO: Pick (first) selection on enter & submit.
            }
        });

        autocompleteArr.push(autocomplete);
        autocompleteInputArr.push(inputField);
    }
}

function prepareSubmission(autocomplete, index) {
    const transalation = {
        'country': 'country',
        'locality': 'city',
        'postal_town': 'city',
        'administrative_area_level_1': 'region',
        'postal_code': 'postalCode'
    };
    const fields = Object.keys(transalation);
    const place = autocomplete.getPlace(); // Get the place details from the autocomplete object.

    setPlaceIdValue(place.place_id);
    updatePhoneDataLanguage(place.formatted_address);

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    for (let i = 0; i < place.address_components.length; i++) {
        const addressTypes = place.address_components[i].types;
        const match = addressTypes.filter(v => fields.includes(v));
        if (match[0] && match[0] === 'country') {
            document.getElementById(`${transalation[match[0]]}-${index}`).value = place.address_components[i].long_name;
        } else if (match[0]) {
            document.getElementById(`${transalation[match[0]]}-${index}`).value = place.address_components[i].short_name;
        }
    }
    document.getElementById(`lat-${index}`).value = place.geometry.location.lat();
    document.getElementById(`lng-${index}`).value = place.geometry.location.lng();
    // Combine address
    const placeStreetNum = place.address_components.filter(e => e.types.includes('street_number'))[0];

    let streetNum = "";
    if (streetNum) {
        streetNum = placeStreetNum
    }

    streetNum = streetNum ? streetNum.concat(' ') : ''

    const placeRoute = place.address_components.filter(e => e.types.includes('route'))[0];

    if (placeRoute) {
        route = placeRoute.short_name;
    }

    document.getElementById(`line2-${index}`).value = streetNum.concat(route);
}

function initMap() {

    document.addEventListener("DOMContentLoaded", (event) => {
        initializeAutocomplete();
    });
}

function addCtaParameterInputFieds(ctaWrapperDom) {
    let data = ctaWrapperDom.dataset;

    for (let key in data) {
        if (key.startsWith('param')) {
            const param = key.replace('param', '').toLowerCase();

            const input = document.createElement("input");
            input.type = "hidden";
            input.name = param;
            input.value = data[key];

            ctaWrapperDom.querySelector("form").appendChild(input);
        }
    }
}

function getAdditionalCtaParams() {
    const metaCtaParamsElem = document.querySelector('meta[name="cta-params"]');

    if (!metaCtaParamsElem || (metaCtaParamsElem && !metaCtaParamsElem.content)) return {};

    const additionalUrlParamsContent = document.querySelector(
        'meta[name="cta-params"]'
    ).content;

    return JSON.parse(
        '{"' +
        additionalUrlParamsContent
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
        '"}'
    );
}

function addParamsFieldsToCtaForm(ctaForm, ctaAdditionalParams) {
    for (const field in ctaAdditionalParams) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = field;
        input.value = ctaAdditionalParams[field];

        ctaForm.appendChild(input);
    }
}

function addPlaceIdFieldToCtaForm(ctaForm) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "placeId";

    ctaForm.appendChild(input);
}

function setPlaceIdValue(placeId) {
    document.querySelector('[name="placeId"]').value = placeId;
}

function updatePhoneDataLanguage(formattedAddress) {
    const countryMap = {
        'Australia': {
            code: 'au',
            placeholder: '+61 2 8311 8689'
        },
        'New Zealand': {
            code: 'nz',
            placeholder: '+64 3 123 4567'
        },
        'Singapore': {
            code: 'sg',
            placeholder: '+65 6123 4567'
        }
    }
    
    const phoneNumberInput = document.querySelector('[data-name="Phone Number"]');

    if (phoneNumberInput) {
        const addressArr = formattedAddress.split(',');
        const country = addressArr[addressArr.length - 1]
            .replace(/[^a-zA-Z ]/g, "")
            .trim();

        if (!(country in countryMap)) return 'au';

        itiObj.setCountry(countryMap[country]['code']);
        phoneNumberInput.placeholder = countryMap[country]['placeholder']
    }
}
